import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";
import Icon from '../../components/Icons/icon';

const Retirement = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Aposentadoria e Auxílios"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
        ]}
      >
        {isDesktop && <Aside />}

        <P.Article>
          <p>
            No Brasil, existem diferentes modelos de aposentadoria, cada um com
            regras e requisitos específicos, por isso é de suma importância o
            acompanhamento profissional, para que você não seja aposentado em
            uma modalidade que vai te prejudicar.
          </p>

          <p>
            Abaixo temos um campo específico para o seu problema, busque nas
            opções abaixo qual delas é a que se encaixa com você:
          </p>

          <P.Checklist>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Aposentadoria por Idade</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Aposentadoria por Tempo de Contribuição</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>
                Aposentadoria por Invalidez Permanente ou Incapacidade
                Permanente
              </span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Aposentadoria por Pontos</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Benefício de Prestação Continuada</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Pensão por Morte</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>
                Auxílio por Incapacidade Temporária (Acidentário ou não)
              </span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>Aposentadoria por Idade do Segurado Especial</span>
            </li>
            <li>
              <Icon name="ArrowRightSmall" /> <span>Auxílio Acidente</span>
            </li>
          </P.Checklist>

          <p>
            Além dos citados, existem outras opções, que você pode conversar
            conosco pelo WhatsApp ao lado.
          </p>

          <p>
            Está com um problema Trabalhista ou do Consumidor? Faça contato que
            também podemos ajudar!
          </p>
        </P.Article>

        {!isDesktop && <Aside />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Aposentadoria e Auxílios"
    description="Suporte jurídico em questões de aposentadoria e auxílios. Orientação para garantir seus direitos a benefícios, revisões e recursos, com atendimento especializado e confiável."
  />
);

export default Retirement;
